/* NotFound.module.css */
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2;
    color: #333;
    font-family: Arial, sans-serif;
    text-align: center;
}

.illustration {
    font-size: 5rem;
    margin-bottom: 20px;
}

.bounce {
    animation: bounce 2s infinite;
}

.heading {
    font-size: 6rem;
    margin: 0;
}

.text {
    font-size: 1.5rem;
    margin: 10px 0;
}

.link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    text-decoration: none;
    color: white;
    background-color: #007BFF;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.linkHover {
    background-color: #0056b3;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-10px);
    }
}

@media (max-width: 768px) {
    .heading {
        font-size: 4rem;
    }

    .text {
        font-size: 1.2rem;
    }

    .link {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .heading {
        font-size: 3rem;
    }

    .text {
        font-size: 1rem;
    }

    .link {
        font-size: 0.8rem;
    }
}
